import { Page, View, Document, Font } from '@react-pdf/renderer'
import mulishNormal from '../../fonts/Mulish_Regular.ttf'
import mulishBold from '../../fonts/Mulish_Bold.ttf'
import mulishUltraBold from '../../fonts/Mulish_ExtraBold.ttf'
import PdfHeader from './components/PdfHeader'
import { PDF_PALET_OF_COLORS } from './constants/pdfConstants'
import { styles } from './styles/pdfStyles'
// import PdfFooter from './components/PdfFooter'
import PdfGeneralSkills from './components/PdfGeneralSkills'
import PdfWorkExperince from './components/PdfWorkExperince'
import PdfChart from './components/PdfChart'
import PdfIdiomsAndAboutMe from './components/PdfIdiomsAndAboutMe'
import PdfSoftSkils from './components/PdfSoftSkils'
import PdfSOftSkillsChart from './components/PdfSOftSkillsChart'
// import PdfIdioms from './components/PdfIdioms'

Font.register({
  family: 'Mulish',
  fonts: [
    {
      src: mulishNormal,
      fontWeight: 'normal',
    },
    {
      src: mulishBold,
      fontWeight: 'bold',
    },
    {
      src: mulishUltraBold,
      fontWeight: 'ultrabold',
    },
  ],
})

export const PdfToDownload = ({ data = [] }) => {
  const {
    user = {},
    talen_skills = [],
    talen_soft_skills = [],
    languaje_lang_levels = [],
    years = '',
    range_year = {},
    works = [],
    category_job = {},
    name_category_job = '',
    talents_about = {},
  } = data

  const totalElementsToShow = 8

  const { username = '' } = user

  // const compareByPercentage = (a, b) => {
  //   return b?.percentage?.value - a?.percentage?.value
  // }

  // const elementsToShow = talen_skills.slice(0, totalElementsToShow)

  const renderSkills = talen_skills ?? []
  const renderSoftSkills = talen_soft_skills ?? []

  const totalWorksToShow = 3
  // const totalIdiomsToShow = 5
  // const totalSkillsToShow = 8

  // const idiomsToShow = languaje_lang_levels.slice(0, totalIdiomsToShow)

  const worksToShow = works.slice(0, totalWorksToShow)

  const renderWorkExperience = worksToShow

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PdfHeader
          styles={styles}
          name_category_job={name_category_job}
          category_job={category_job}
          range_year={range_year}
          username={username}
          years={years}
          pdf_palet_of_colors={PDF_PALET_OF_COLORS}
        />
        <View
          style={{
            marginTop: '16px',
          }}
        >
          <PdfIdiomsAndAboutMe
            languaje_lang_levels={languaje_lang_levels}
            aboutMe={talents_about?.about_me ?? ''}
          />
        </View>

        <View>
          <View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignContent: 'center',
                gap: '8px',
                width: '100%',
              }}
            >
              <View
                style={{
                  width: '100%',
                  backgroundColor: '#FFFFFF',
                  padding: '8px',
                  borderRadius: '8px',
                }}
              >
                <PdfChart renderSkills={renderSkills} />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '16px',
                    width: '100%',
                  }}
                >
                  {talen_skills.length > totalElementsToShow && (
                    <PdfGeneralSkills renderSkills={renderSkills} />
                  )}
                </View>
              </View>
              {talen_soft_skills?.length > 0 && (
                <View
                  style={{
                    width: '100%',
                    backgroundColor: '#FFFFFF',
                    padding: '8px',
                    borderRadius: '8px',
                  }}
                >
                  <PdfSOftSkillsChart renderSoftSkills={renderSoftSkills} />
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '16px',
                      width: '100%',
                    }}
                  >
                    {talen_soft_skills?.length > totalElementsToShow && (
                      <PdfSoftSkils renderSkills={renderSoftSkills} />
                    )}
                  </View>
                </View>
              )}
            </View>

            <View
              id="pdf-container"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <PdfWorkExperince renderWorkExperience={renderWorkExperience} />
            </View>
          </View>
        </View>

        {/* <PdfFooter renderWorkExperience={works} renderSkills={talen_skills} /> */}
      </Page>
    </Document>
  )
}
