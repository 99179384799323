import { BASE_URL } from '../constants'

export const getCountries = () => {
  const URL = `${BASE_URL}/getCountries`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => res.countries ?? [])

    return response ?? []
  } catch (error) {
    console.log(error)
  }
}

export const getStates = (country) => {
  const URL = `${BASE_URL}/getstates/${country}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => res.states)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getCities = (state) => {
  const URL = `${BASE_URL}/getcities/${state}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => res.cities)

    return response
  } catch (error) {
    console.log(error)
  }
}
