import { useEffect, useState } from 'react'
import Modal from '../../../components/Modal'
import useResize from '../../../hooks/useResize'
import { OUT_OF_THE_APP } from '../../../utils/constants'
import TabBar from './TabBar'
import {
  getCategories,
  getListOfSkillsCategories,
} from '../../../services/talents'
import { TAGS } from '../../../services/Tags'
import { useQuery } from '@tanstack/react-query'
import Xicon from '../../../icons/filters/Xicon'
import { CANDIDATES_TAGS } from '../../../services/Tags/candidates'
import {
  ENGLISH_LEVEL,
  EXPERIENCE_LEVEL,
} from '../../../utils/constants/filters'
import Button from '../../../components/Button'
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import {
  useListOfTalentsStore,
  useSearchTalentsFiltersStore,
} from '../../../store'
import { useTranslation } from 'react-i18next'
import { getCompanySectors } from '../../../services/CompanyData'
import { getCountries } from '../../../services/Adress'


const FiltersModal = ({
  selectedButton,
  setSelectedButton,
  handleSubmit,
  page,
  setGlobalPage,
  globalData,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const { setData } = useListOfTalentsStore()

  const {
    selectedCategories,
    setSelectedCategories,

    currentCategories,
    setCurrentCategories,

    filteredCategories,
    setFilteredCategories,

    skills,
    setSkills,

    filteredSkills,
    setFilteredSkills,

    filteredSectors,
    setFilteredSectors,

    filteredCountries,
    setFilteredCountries,

    selectedSkills,
    setSelectedSkills,

    languages,
    setLanguages,

    selectedLanguages,
    setSelectedLanguages,

    experience,
    setExperience,

    selectedExperience,
    setSelectedExperience,

    sectors,
    setSectors,

    selectedSectors,
    setSelectedSectors,

    countries,
    setCountries,

    selectedCountries,
    setSelectedCountries,

    keywordGlobalValue,
  } = useSearchTalentsFiltersStore()

  const [searchTermCategory, setSearchTermCategory] = useState('')
  const [searchTermSkills, setSearchTermSkill] = useState('')
  const [searchTermSector, setSearchTermSector] = useState('')
  const [searchTermCountry, setSearchTermCountry] = useState('')
  console.log('filteredCountries', filteredCountries)
  const filterCategories = (categories, term) => {
    return categories.filter((category) =>
      category.name.toLowerCase().includes(term.toLowerCase())
    )
  }

  const filterSkills = (skills, term) => {
    return skills.filter((skills) =>
      skills.skill?.name.toLowerCase().includes(term.toLowerCase())
    )
  }

  const filterSectors = (sectors, term) => {
    return sectors.filter((sector) =>
      sector?.name.toLowerCase().includes(term.toLowerCase())
    )
  }

  const filterCountries = (countries, term) => {
    return countries?.filter((country) =>
      country?.name.toLowerCase().includes(term.toLowerCase())
    )
  }

  const viewportSize = useResize()

  const { data: categoriesData = [], isSuccess: isSuccessCategories } =
    useQuery([TAGS.categories], getCategories)

  const {
    data: skillsCategoriesData = [],
    isSuccess: isSuccessSkillsCategories,
  } = useQuery([CANDIDATES_TAGS.get_skills_categories], () =>
    getListOfSkillsCategories(0)
  )

  const { data: companySectorsData = [], isSuccess: isSuccessCompanySectors } =
    useQuery([TAGS.list_of_company_sectors], getCompanySectors)

  const { data: dataContries = [], isSuccess: isSuccessCountries  } = useQuery(
      ['countries-test-test'],
      getCountries
    )
  
    const transformDataContries = dataContries?.map(({ id, country_name }) => ({
      id,
      name: country_name,
    }))

    console.log('transformDataContries', transformDataContries)

  const handleCloseModal = () => {
    const modal = bootstrap.Modal.getOrCreateInstance('#filter-modal')
    modal.hide()
  }

  const handleCategoryChange = (id) => {
    const updatedCategories = currentCategories?.map((category) => {
      if (category.id === id) {
        return { ...category, checked: !category.checked }
      }
      return category
    })

    const filtered = filterCategories(categoriesData, searchTermCategory)

    const selectedCategories = updatedCategories?.filter(
      (category) => category.checked
    )
    setSelectedCategories(selectedCategories)
    setCurrentCategories(updatedCategories)
    setFilteredCategories(filtered)
  }

  const handleDeleteCategory = (id) => {
    const updatedCategories = currentCategories?.map((category) => {
      if (category.id === id) {
        return { ...category, checked: false }
      }
      return category
    })

    const selectedCategories = updatedCategories?.filter(
      (category) => category.checked
    )
    setSelectedCategories(selectedCategories)
    setCurrentCategories(updatedCategories)
  }

  const handleSkillChange = (id) => {
    const updatedSkills = skills.map((skill) => {
      if (skill.id === id) {
        return { ...skill, checked: !skill.checked }
      }
      return skill
    })

    const filtered = filterSkills(skillsCategoriesData, searchTermSkills)

    const selectedSkills = updatedSkills.filter((skill) => skill.checked)
    setSelectedSkills(selectedSkills)
    setSkills(updatedSkills)
    setFilteredSkills(filtered)
  }

  const handleDeleteSkill = (id) => {
    const updatedSkills = skills.map((skill) => {
      if (skill.id === id) {
        return { ...skill, checked: false }
      }
      return skill
    })

    const selectedSkills = updatedSkills.filter((skill) => skill.checked)
    setSelectedSkills(selectedSkills)
    setSkills(updatedSkills)
  }

  const handleLanguagesChange = (id) => {
    const updatedLanguages = languages.map((language) => {
      if (language.id === id) {
        return { ...language, checked: !language.checked }
      }
      return language
    })

    const selectedLanguages = updatedLanguages.filter(
      (language) => language.checked
    )
    setSelectedLanguages(selectedLanguages)
    setLanguages(updatedLanguages)
  }

  const handleDeleteLanguages = (id) => {
    const updatedLanguage = languages.map((language) => {
      if (language.id === id) {
        return { ...language, checked: false }
      }
      return language
    })

    const selectedLanguage = updatedLanguage.filter(
      (language) => language.checked
    )
    setSelectedLanguages(selectedLanguage)
    setLanguages(updatedLanguage)
  }

  const handleExperienceChange = (id) => {
    const updatedExperience = experience.map((experience) => {
      if (experience.id === id) {
        return { ...experience, checked: !experience.checked }
      }
      return experience
    })

    const selectedExperience = updatedExperience.filter(
      (experience) => experience.checked
    )
    setSelectedExperience(selectedExperience)
    setExperience(updatedExperience)
  }

  const handleDeleteExperience = (id) => {
    const updatedExperience = experience.map((experience) => {
      if (experience.id === id) {
        return { ...experience, checked: false }
      }
      return experience
    })

    const selectedExperience = updatedExperience.filter(
      (experience) => experience.checked
    )
    setSelectedExperience(selectedExperience)
    setExperience(updatedExperience)
  }

  const handleSectorsChange = (id) => {
    const updatedSectors = sectors.map((sector) => {
      if (sector.id === id) {
        return { ...sector, checked: !sector.checked }
      }
      return sector
    })

    const filtered = filterSectors(companySectorsData, searchTermSector)

    const selectedSectors = updatedSectors.filter((sector) => sector.checked)
    setSelectedSectors(selectedSectors)
    setSectors(updatedSectors)
    setFilteredSectors(filtered)
  }

  const handleCountriesChange = (id) => {
    const updatedCountries = countries.map((country) => {
      if (country.id === id) {
        return { ...country, checked: !country.checked }
      }
      return country
    })

    const filtered = filterCountries(transformDataContries, searchTermCountry)

    const selectedCountries = updatedCountries.filter((country) => country.checked)
    setSelectedCountries(selectedCountries)
    setCountries(updatedCountries)
    setFilteredCountries(filtered)
  }

  const handleDeleteSectors = (id) => {
    const updatedSectors = sectors.map((sector) => {
      if (Number(sector.id) === Number(id)) {
        return { ...sector, checked: false }
      }
      return sector
    })

    const selectedSectors = updatedSectors.filter((sector) => sector.checked)
    setSelectedSectors(selectedSectors)
    setSectors(updatedSectors)
  }

  const handleDeleteCountries = (id) => {
    const updatedCountries = countries.map((country) => {
      if (Number(country.id) === Number(id)) {
        return { ...country, checked: false }
      }
      return country
    })

    const selectedCountries = updatedCountries.filter((country) => country.checked)
    setSelectedCountries(selectedCountries)
    setCountries(updatedCountries)
  }

  const renderCategories = (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-3">
        <input
          className="form-control"
          type="text"
          onChange={(e) => {
            const term = e.target.value
            setSearchTermCategory(term)

            const filtered = filterCategories(currentCategories, term)
            setFilteredCategories(filtered)
          }}
          value={searchTermCategory}
          placeholder={t('search_categories')}
        />
      </div>
      <div className="overflow-auto mt-3" style={{ height: '150px' }}>
        {filteredCategories?.map(({ id, name }) => (
          <div
            key={id}
            className="custom-control custom-checkbox form-check p-0"
            id={id}
            value={searchTermCategory}
          >
            <input
              className="me-2 border border-2 border-on-bg-primary mycheck"
              type="checkbox"
              value={id}
              key={id}
              checked={selectedCategories.some(
                (selectedCategory) => selectedCategory?.id === id
              )}
              onChange={() => handleCategoryChange(id)}
            />
            <label className="custom-control-label">{name}</label>
          </div>
        ))}
      </div>
      <div>
        <div className="d-flex flex-wrap mt-3 gap-1">
          {selectedCategories?.map(({ id, name }) => (
            <span
              key={id}
              className="d-flex align-items-center text-on-bg-CTA-primary-selected bg-bg-CTA-primary-selected badge rounded rounded-pill cursor-pointer"
            >
              {name}
              <div onClick={() => handleDeleteCategory(id)}>
                <Xicon className="cursor-pointer" />
              </div>
            </span>
          ))}
        </div>
      </div>
    </>
  )

  const renderSkills = (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-3">
        <input
          className="form-control"
          type="text"
          onChange={(e) => {
            const term = e.target.value
            setSearchTermSkill(term)

            const filtered = filterSkills(skills, term)
            setFilteredSkills(filtered)
          }}
          value={searchTermSkills}
          placeholder={t('skills')}
        />
      </div>
      <div className="overflow-auto" style={{ height: '150px' }}>
        {filteredSkills?.map((props) => (
          <div
            key={props?.id}
            className="custom-control custom-checkbox form-check p-0"
            id={props?.id}
          >
            <input
              className="me-2 border border-2 border-on-bg-primary mycheck"
              type="checkbox"
              checked={selectedSkills.some(
                (selectedSkill) => selectedSkill?.id === props?.id
              )}
              onChange={() => handleSkillChange(props?.id)}
            />
            <label className="custom-control-label">{props?.skill?.name}</label>
          </div>
        ))}
      </div>
      <div className="d-flex flex-wrap mt-3 gap-1 history-list-of-items">
        {selectedSkills?.map(({ id, skill }) => (
          <span
            key={id}
            className="d-flex justify-content-center align-items-center text-on-bg-CTA-primary-selected bg-bg-CTA-primary-selected badge rounded rounded-pill cursor-pointer"
          >
            {skill?.name}
            <div onClick={() => handleDeleteSkill(id)}>
              <Xicon className="cursor-pointer" />
            </div>
          </span>
        ))}
      </div>
    </>
  )

  const renderLanguages = (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-3">
        <input
          className="form-control"
          type="text"
          placeholder={t('languages')}
        />
      </div>
      <div className="overflow-auto" style={{ height: '150px' }}>
        {languages?.map(({ id, name }) => (
          <div
            key={id}
            className="custom-control custom-checkbox form-check p-0"
            id={id}
          >
            <input
              className="me-2 border border-2 border-on-bg-primary mycheck"
              type="checkbox"
              checked={selectedLanguages.some(
                (selectedLanguages) => selectedLanguages?.id === id
              )}
              onChange={() => handleLanguagesChange(id)}
            />
            <label className="custom-control-label">{name}</label>
          </div>
        ))}
      </div>
      <div className="d-flex flex-wrap mt-3 gap-1 history-list-of-items">
        {selectedLanguages?.map(({ id, name }) => (
          <span
            key={id}
            className="d-flex justify-content-center align-items-center text-on-bg-CTA-primary-selected bg-bg-CTA-primary-selected badge rounded rounded-pill cursor-pointer"
          >
            {name}
            <div onClick={() => handleDeleteLanguages(id)}>
              <Xicon className="cursor-pointer" />
            </div>
          </span>
        ))}
      </div>
    </>
  )

  const renderExperience = (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-3">
        <input
          className="form-control"
          type="text"
          placeholder={t('experience')}
        />
      </div>
      <div className="overflow-auto" style={{ height: '150px' }}>
        {experience?.map(({ id, name }) => (
          <div
            key={id}
            className="custom-control custom-checkbox form-check p-0"
            id={id}
          >
            <input
              className="me-2 border border-2 border-on-bg-primary mycheck"
              type="checkbox"
              checked={selectedExperience.some(
                (selectedExperience) => selectedExperience?.id === id
              )}
              onChange={() => handleExperienceChange(id)}
            />
            <label className="custom-control-label">{name}</label>
          </div>
        ))}
      </div>
      <div className="d-flex flex-wrap mt-3 gap-1 history-list-of-items">
        {selectedExperience?.map(({ id, name }) => (
          <span
            key={id}
            className="d-flex justify-content-center align-items-center text-on-bg-CTA-primary-selected bg-bg-CTA-primary-selected badge rounded rounded-pill cursor-pointer"
          >
            {name}
            <div onClick={() => handleDeleteExperience(id)}>
              <Xicon className="cursor-pointer" />
            </div>
          </span>
        ))}
      </div>
    </>
  )

  const renderSectors = (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-3">
        <input
          className="form-control"
          type="text"
          placeholder={t('sector')}
          onChange={(e) => {
            const term = e.target.value
            setSearchTermSector(term)

            const filtered = filterSectors(sectors, term)
            setFilteredSectors(filtered)
          }}
          value={searchTermSector}
        />
      </div>
      <div className="overflow-auto" style={{ height: '150px' }}>
        {filteredSectors?.map(({ id, name }) => (
          <div
            key={id}
            className="custom-control custom-checkbox form-check p-0"
            id={id}
          >
            <input
              className="me-2 border border-2 border-on-bg-primary mycheck"
              type="checkbox"
              checked={selectedSectors.some(
                (selectedSectors) => selectedSectors?.id === id
              )}
              onChange={() => handleSectorsChange(id)}
            />
            <label className="custom-control-label">{name}</label>
          </div>
        ))}
      </div>
      <div className="d-flex flex-wrap mt-3 gap-1 history-list-of-items">
        {selectedSectors?.map(({ id, name }) => (
          <span
            key={id}
            className="d-flex justify-content-center align-items-center text-on-bg-CTA-primary-selected bg-bg-CTA-primary-selected badge rounded rounded-pill cursor-pointer"
          >
            {name}
            <div onClick={() => handleDeleteSectors(id)}>
              <Xicon className="cursor-pointer" />
            </div>
          </span>
        ))}
      </div>
    </>
  )

  const renderCountries = (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-3">
        <input
          className="form-control"
          type="text"
          placeholder={t('country')}
          onChange={(e) => {
            const term = e.target.value
            setSearchTermCountry(term)

            const filtered = filterCountries(countries, term)
            setFilteredCountries(filtered)
          }}
          value={searchTermCountry}
        />
      </div>
      <div className="overflow-auto" style={{ height: '150px' }}>
        {filteredCountries?.map(({ id, name }) => (
          <div
            key={id}
            className="custom-control custom-checkbox form-check p-0"
            id={id}
          >
            <input
              className="me-2 border border-2 border-on-bg-primary mycheck"
              type="checkbox"
              checked={selectedCountries.some(
                (selectedCountries) => selectedCountries?.id === id
              )}
              onChange={() => handleCountriesChange(id)}
            />
            <label className="custom-control-label">{name}</label>
          </div>
        ))}
      </div>
      <div className="d-flex flex-wrap mt-3 gap-1 history-list-of-items">
        {selectedCountries?.map(({ id, name }) => (
          <span
            key={id}
            className="d-flex justify-content-center align-items-center text-on-bg-CTA-primary-selected bg-bg-CTA-primary-selected badge rounded rounded-pill cursor-pointer"
          >
            {name}
            <div onClick={() => handleDeleteCountries(id)}>
              <Xicon className="cursor-pointer" />
            </div>
          </span>
        ))}
      </div>
    </>
  )

  const renderFilters = {
    1: renderCategories,
    2: renderSkills,
    3: renderLanguages,
    4: renderExperience,
    5: renderSectors,
    6: renderCountries
  }

  useEffect(() => {
    if (
      (isSuccessCategories && currentCategories?.length === 0) ||
      currentCategories?.length === 0
    ) {
      setFilteredCategories(
        filteredCategories?.length === 0 ? categoriesData : filteredCategories
      )
      setCurrentCategories(
        currentCategories?.length === 0 ? categoriesData : currentCategories
      )
    }
  }, [isSuccessCategories])

  useEffect(() => {
    if (
      (isSuccessSkillsCategories && skills?.length === 0) ||
      filteredSkills?.length === 0
    ) {
      setSkills(selectedSkills?.length === 0 ? skillsCategoriesData : skills)
      setFilteredSkills(
        filteredSkills?.length === 0 ? skillsCategoriesData : filteredSkills
      )
    }
  }, [isSuccessSkillsCategories])

  useEffect(() => {
    if (
      (isSuccessCompanySectors && sectors?.length === 0) ||
      filteredSectors?.length === 0
    ) {
      setSectors(selectedSectors?.length === 0 ? companySectorsData : sectors)
      setFilteredSectors(
        filteredSectors?.length === 0 ? companySectorsData : filteredSectors
      )
    }
  }, [isSuccessCompanySectors])

  useEffect(() => {
    if (
      (isSuccessCountries && countries?.length === 0) ||
      filteredCountries?.length === 0
    ) {
      setCountries(selectedCountries?.length === 0 ? transformDataContries : countries)
      setFilteredCountries(
        filteredCountries?.length === 0 ? transformDataContries : filteredCountries
      )
    }
  }, [isSuccessCountries])

  useEffect(() => {
    setLanguages(ENGLISH_LEVEL)
  }, [languages])

  useEffect(() => {
    setExperience(EXPERIENCE_LEVEL)
  }, [experience])

  useEffect(() => {
    if (
      !OUT_OF_THE_APP &&
      globalData?.length === 0 &&
      keywordGlobalValue === ''
    ) {
      handleSubmit(onSubmit())
    }
  }, [])

  useEffect(() => {
    if (OUT_OF_THE_APP && globalData?.length === 0) {
      handleSubmit(onSubmit())
    }
  }, [])

  useEffect(() => {
    if (page !== 0) handleSubmit(onSubmit())
  }, [page])

  return (
    <Modal
      modalId="filter-modal"
      title={t('search_filters')}
      titleClassName="text-on-bg-primary fw-bolder"
    >
      <div>
        <div className="d-flex flex-column justify-content-center align-items-center gap-3">
          <TabBar
            viewportSize={viewportSize}
            selectedButton={selectedButton}
            setSelectedButton={setSelectedButton}
            t={t}
          />
        </div>
        <div className="p-1">{renderFilters[selectedButton]}</div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <Button
            className="btn-white text-on-bg-primary border border-on-bg-primary"
            type="button"
            onClick={handleCloseModal}
          >
            {t('go_back')}
          </Button>
          <Button
            type="submit"
            onClick={() => {
              setGlobalPage(0)
              setData([])
              handleCloseModal()
            }}
          >
            {t('apply_filters')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default FiltersModal
