import Button from '../../../components/Button'
import ArrowDownIcon from '../../../icons/ArrowDownIcon'
import { OUT_OF_THE_APP, VIEWPORT_LG } from '../../../utils/constants'
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import { TYPE_OF_TAB_BAR_BUTTONS } from './TabBar'
import Badge from '../../../components/Badge/Badge'

import CustomModal from '../../../components/Modals/CustomModal'
import Swal from 'sweetalert2'
import FilterModalIcon from '../../../icons/Modals/FilterModalIcon'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../../Routes/routes'
import { useNavigate } from 'react-router-dom'
import FilterModalIconTwo from '../../../icons/Modals/FilterModalIconTwo'
import useResize from '../../../hooks/useResize'
import FiltersIcon from '../../../icons/FiltersIcon'
import ArrowRigthIcon from '../../../icons/ArrowRigthIcon'
import { useTranslation } from 'react-i18next'

const Filters = ({
  setSelectedButton,
  selectedCategories,
  selectedSkills,
  selectedLanguages,
  selectedExperience,
  selectedSectors,
  selectedCountries,
  setGlobalPage,
  setData,
  setFilterByWords,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isTalentsView = window.location.pathname === PRIVATE_ROUTES.talents
  const viewportSize = useResize()

  const { all, categories, skills, english, experience, sector, country } =
    TYPE_OF_TAB_BAR_BUTTONS

  const handleOpenFiltersModal = ({ typeFilter = all }) => {
    if (OUT_OF_THE_APP) {
      if (!isTalentsView) {
        CustomModal({
          Image: () => <FilterModalIcon />,
          title: t('find_your_talent'),
          description: t(
            'sign_up_to_use_the_filter_tool_to_find_your_ideal_candidate_faster_or_browse_all_available_talent_without_signing_up'
          ),
          textPrimaryButton: t('see_all_talents'),
          textSecondaryButton: t('sign_up_two'),
          classNameTextPrimaryButton: `${
            viewportSize[0] <= VIEWPORT_LG ? 'font-extra-small' : 'font-small'
          } `,
          classNameSecondaryButton: `${
            viewportSize[0] <= VIEWPORT_LG ? 'font-extra-small' : 'font-small'
          } `,
          handlePrimaryButton: () => {
            navigate(PRIVATE_ROUTES.talents)
            Swal.close()
          },
          handleSecondaryButton: () => {
            navigate(PUBLIC_ROUTES.register_by_company)
            Swal.close()
          },
        })
        return
      }
      if (isTalentsView) {
        CustomModal({
          Image: () => <FilterModalIconTwo />,
          title: t('become_a_pro_for_free'),
          description: t(
            'register_to_access_filter_and_save_your_favorite_candidates'
          ),
          textPrimaryButton: t('go_pro_for_free_now'),
          classNameTextPrimaryButton: `${
            viewportSize[0] <= VIEWPORT_LG ? 'font-extra-small' : 'font-small'
          } `,
          handlePrimaryButton: () => {
            navigate(PUBLIC_ROUTES.register_by_company)
            Swal.close()
          },
          showSecondaryButton: false,
          titleClassName: 'mt-4',
        })
        return
      }
      return
    }

    setSelectedButton(typeFilter)
    const modal = bootstrap.Modal.getOrCreateInstance('#filter-modal')
    modal.show()
  }

  const allCategories = selectedCategories?.length
  const allSkills = selectedSkills?.length
  const allLanguages = selectedLanguages?.length
  const allExperience = selectedExperience?.length
  const allSectors = selectedSectors?.length
  const allCountries = selectedCountries?.length

  return (
    <div className="border rounded p-3 shadow-sm mb-3">
      {' '}
      {viewportSize[0] <= VIEWPORT_LG ? (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => handleOpenFiltersModal({ typeFilter: categories })}
        >
          <div>
            <FiltersIcon />
            <span className="text-decoration-underline text-on-bg-primary ms-2">
              {t('filters')}
            </span>
          </div>
          <ArrowRigthIcon />
        </div>
      ) : (
        <div className="d-flex align-items-center gap-3 justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <Button
              className="border border-on-bg-Tertiary text-on-bg-secondary text-start w-100 d-flex align-items-center justify-content-between"
              onClick={() => handleOpenFiltersModal({ typeFilter: categories })}
              type="button"
              role="button"
            >
              <span>
                {allCategories > 0 ? (
                  <div className="d-flex align-items-center gap-1">
                    <span>{t('category')}</span>
                    <Badge className="bg-bg-CTA-complementary-pressed  text-on-bg-primary rounded-pill me-2">
                      {`+${allCategories}`}
                    </Badge>
                  </div>
                ) : (
                  <span>{t('category')}</span>
                )}
              </span>
              <ArrowDownIcon />
            </Button>
            <Button
              className="border border-on-bg-Tertiary text-on-bg-secondary text-start w-100 d-flex align-items-center justify-content-between"
              onClick={() => handleOpenFiltersModal({ typeFilter: skills })}
              type="button"
              role="button"
            >
              {allSkills > 0 ? (
                <div className="d-flex align-items-center gap-1">
                  <span>{t('skills')}</span>
                  <Badge className="bg-bg-CTA-complementary-pressed  text-on-bg-primary rounded-pill me-2">
                    {`+${allSkills}`}
                  </Badge>
                </div>
              ) : (
                <span>{t('skills')}</span>
              )}
              <ArrowDownIcon />
            </Button>
            <Button
              className="border border-on-bg-Tertiary text-on-bg-secondary text-start w-100 d-flex align-items-center justify-content-between"
              onClick={() => handleOpenFiltersModal({ typeFilter: english })}
              type="button"
              role="button"
            >
              {allLanguages > 0 ? (
                <div className="d-flex align-items-center gap-1">
                  <span>{t('english')}</span>
                  <Badge className="bg-bg-CTA-complementary-pressed  text-on-bg-primary rounded-pill me-2">
                    {`+${allLanguages}`}
                  </Badge>
                </div>
              ) : (
                <span>{t('english')}</span>
              )}
              <ArrowDownIcon />
            </Button>
            <Button
              className="border border-on-bg-Tertiary text-on-bg-secondary text-start w-100 d-flex align-items-center justify-content-between"
              onClick={() => handleOpenFiltersModal({ typeFilter: experience })}
              type="button"
              role="button"
            >
              {allExperience > 0 ? (
                <div className="d-flex align-items-center gap-1">
                  <span>{t('experience')}</span>
                  <Badge className="bg-bg-CTA-complementary-pressed  text-on-bg-primary rounded-pill me-2">
                    {`+${allExperience}`}
                  </Badge>
                </div>
              ) : (
                <span>{t('experience')}</span>
              )}
              <ArrowDownIcon />
            </Button>
            <Button
              className="border border-on-bg-Tertiary text-on-bg-secondary text-start w-100 d-flex align-items-center justify-content-between"
              onClick={() => handleOpenFiltersModal({ typeFilter: sector })}
              type="button"
              role="button"
            >
              {allSectors > 0 ? (
                <div className="d-flex align-items-center gap-1">
                  <span>{t('sector')}</span>
                  <Badge className="bg-bg-CTA-complementary-pressed  text-on-bg-primary rounded-pill me-2">
                    {`+${allSectors}`}
                  </Badge>
                </div>
              ) : (
                <span>{t('sector')}</span>
              )}
              <ArrowDownIcon />
            </Button>
            <Button
              className="border border-on-bg-Tertiary text-on-bg-secondary text-start w-100 d-flex align-items-center justify-content-between"
              onClick={() => handleOpenFiltersModal({ typeFilter: country })}
              type="button"
              role="button"
            >
              {allCountries > 0 ? (
                <div className="d-flex align-items-center gap-1">
                  <span>{t('country')}</span>
                  <Badge className="bg-bg-CTA-complementary-pressed  text-on-bg-primary rounded-pill me-2">
                    {`+${allCountries}`}
                  </Badge>
                </div>
              ) : (
                <span>{t('country')}</span>
              )}
              <ArrowDownIcon />
            </Button>
          </div>
          <div className="d-flex flex-column align-items-start gap-1">
            <input
              className="form-control w-100"
              placeholder="Buscar por profesión"
              onChange={(evt) => {
                setFilterByWords(evt?.target?.value)
              }}
            />
          </div>
          <Button
            type="submit"
            onClick={() => {
              setGlobalPage(0)
              setData([])
            }}
          >
            <span>{t('search')}</span>
          </Button>
        </div>
      )}
    </div>
  )
}

export default Filters
